<template>
  <app-layout v-if="authenticated">
    <div
      class="
        m-auto
        space-y-5
        py-20
        px-10
        flex flex-col
        items-center
        justify-center
        text-center
      "
    >
      <app-icon-invalid-link class="mb-5" />
      <p
        v-if="title"
        v-html="title"
        class="font-medium text-lg text-gray-400"
      />
      <p v-if="message" v-html="message" class="text-gray-400" />
    </div>
  </app-layout>
  <app-layout-main v-else>
    <div class="grid place-items-center h-full mx-10">
      <div
        class="
          p-10
          mx-auto
          shadow-lg
          space-y-5
          rounded-xl
          bg-white
          text-center
          flex flex-col
          items-center
          justify-center
        "
      >
        <app-icon-invalid-link class="mb-5" />
        <p
          v-if="title"
          v-html="title"
          class="font-medium text-lg text-gray-400"
        />
        <p v-if="message" v-html="message" class="text-gray-400" />
      </div>
    </div>
  </app-layout-main>
</template>

<script>
export default {
  computed: {
    authenticated() {
      return this.$store.getters["authStore/authenticated"];
    },
    title() {
      return this.$route.query.title ? this.$route.query.title : null;
    },
    message() {
      return this.$route.query.message
        ? this.$route.query.message
        : this.$t("general.invalid_link");
    },
  },
};
</script>
